@font-face {
  font-family: 'Figtree';
  src: local('Figtree'), url(./assets/fonts/Figtree-VariableFont_wght.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Figtree', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 350px;
  background-color: var(--background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --background: #FFFFFF;
  --background-secondary: #FFFFFF;
  --background-hover: #f7f7f7;
  --text-primary: #212121;
  --text-title: #005392;
  --text-link: #333;
  --border: #ddd;

  --border-radius: 16px;
  --shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

[data-theme='dark'] {
  --background: #1e1e1e;
  --background-secondary: #424242;
  --background-hover: #222;
  --text-primary: #EAEAEA;
  --text-title: #D2A520;
  --text-link: #ccc;
  --border: #484848;
}